import React, { useState } from 'react'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer'
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider'
import ListItem from '@material-ui/core/ListItem'
import Typography from '@material-ui/core/Typography'
import MailIcon from '@material-ui/icons/Mail';
import KitchenRoundedIcon from '@material-ui/icons/KitchenRounded';
import SpeakerGroupRoundedIcon from '@material-ui/icons/SpeakerGroupRounded';
import PermIdentityOutlinedIcon from '@material-ui/icons/PermIdentityOutlined';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import { NavLink, Link } from 'react-router-dom'
import MoreHorizRoundedIcon from '@material-ui/icons/MoreHorizRounded';
import PageLoaderHandler from '../utils/PageLoaderHandler'
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import CallIcon from '@material-ui/icons/Call';
import ArrowRightRoundedIcon from '@material-ui/icons/ArrowRightRounded';
import SpeakerGroupOutlinedIcon from '@material-ui/icons/SpeakerGroupOutlined';
import WbIncandescentOutlinedIcon from '@material-ui/icons/WbIncandescentOutlined';
import CameraOutlinedIcon from '@material-ui/icons/CameraOutlined';
import OutdoorGrillOutlinedIcon from '@material-ui/icons/OutdoorGrillOutlined';
import WeekendOutlinedIcon from '@material-ui/icons/WeekendOutlined';
import EmojiObjectsOutlinedIcon from '@material-ui/icons/EmojiObjectsOutlined';
import LocalLaundryServiceOutlinedIcon from '@material-ui/icons/LocalLaundryServiceOutlined';
import RvHookupOutlinedIcon from '@material-ui/icons/RvHookupOutlined';

const useStyles = makeStyles((theme) => ({
  list: {
    width: 300,
    color: '#424242'
  },
  fullList: {
    width: 'auto',
  },
  siteLogo: {
    display: 'flex',
    padding: '5px 0px',
    margin: 'auto',
    width: '30%',
    height: '30%',
    justify: 'center',
    alignItems: 'center',
    '& img': {
      width: '100%',
      height: '100%',
      top: 0,
      left: 0,
      objectFit: 'contain',
    }
  },
  listHeading: {
    fontWeight: 700,
    marginBottom: '-10px',
    color: '#e67700'
  },
  listText: {
    padding: '5px 0px 0px 10px',
    color: "#000000"
  },
  subListText: {
    paddingLeft: '5px',
    color: "#595959"
  },
  subCategory: {
    width: "90%",
    margin: "auto"
  }
}));
export default function SwipeableTemporaryDrawer(iconType) {
  const triggerPageLoader = PageLoaderHandler()
  const active = {
    backgroundColor: '#eeeeee'
 }
  const classes = useStyles();
  const [clicked, setClicked] = useState(null)
  const [state, setState] = useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });
  const handleClicked = (e, link) => {
    if(link === clicked)
    return setClicked(null);
    return setClicked(link);
  }
  const toggleDrawer = (anchor, open) => (event) => {
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setState({ ...state, [anchor]: open });
  };
  const list = (anchor) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === 'top' || anchor === 'bottom',
      })}
      role="presentation"
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <ListItem 
          button
          component={Link} to='/'
          className="routerLink"
          onClick={triggerPageLoader}
          >
            <div className={classes.siteLogo}>
            <img src='/images/ntek.png' alt='NTEK LOGO' />
          </div>
          </ListItem>
          <Divider />
          <div
          onClick={toggleDrawer(anchor, false)}
          >
          <ListItem
           component={NavLink} to='/login'
           className="routerLink"
           activeStyle={active}
           onClick={triggerPageLoader}
          >
            <PermIdentityOutlinedIcon/>
            <Typography variant='subtitle1' className={classes.listText} >
              My Account
              </Typography>
            </ListItem>
            </div>
            <Divider />
        <ListItem>
          <Typography 
          variant='caption' 
          className={classes.listHeading} 
          style={{paddingBottom: '10px'}}>
          BRANDS
          </Typography>
          </ListItem>
        {[
        {
          text: 'Eltak',
          link: 'eltak',
          icon: <SpeakerGroupRoundedIcon />
        },
        {
          text: 'Lentz',
          link: 'lentz',
          icon: <KitchenRoundedIcon />
        }
      ].map((category) => (
        <div
        key={category.link}
        onClick={toggleDrawer(anchor, false)}
        >
        <ListItem 
        button
        component={NavLink} to={`/${category.link}`}
        className="routerLink"
        activeStyle={active}
        onClick={triggerPageLoader}
        >
          { category.icon }
           <Typography variant='subtitle1' className={classes.listText} >
           {category.text}
           </Typography>
        </ListItem>
        </div>
        ))}
          <Divider />
          <ListItem>
          <Typography variant='caption' className={classes.listHeading}>
          CATEGORIES
          </Typography>
          </ListItem>
      <List>
        {[
          {
          text: 'Electronics',
          link: 'electronics',
          icon: <SpeakerGroupOutlinedIcon />,
          subCategories: [
            {link: "televisions", text: "Televisions"}, 
            {link: "music_equipment", text: "Audio/Music Equipment"},
            {link: "tv_accessories", text: "Tv Accessories"},
            {link: "home_appliances", text: "Home Appliances"}
          ]
        }, 
        {
          text: 'Industrial Kitchen Equipment',
          link: 'industrial_kitchen_equipment',
          icon: <OutdoorGrillOutlinedIcon />,
          subCategories: [
            {link: "ovens", text: "Ovens"}, 
            {link: "fryers", text: "Fryers"},
            {link: "moulders", text: "Moulders"},
            {link: "bakers", text: "Bakers"},
            {link: "food_warmer", text: "Food Warmer"},
            {link: "ice_maker", text: "Ice Maker"},
            {link: "mixer", text: "Mixer"},
            {link: "dehydrator", text: "Dehydrator"},
            {link: "juicer", text: "Juicer"},
            {link: "working_tables", text: "Working Tables"},
            {link: "slicer", text: "Slicer"},
            {link: "blenders", text: "Blenders"},
            {link: "extractor", text: "Extractor"}
          ]
        }, 
        {
          text: 'Security & Surveillance',
          link: 'security_surveillance',
          icon: <CameraOutlinedIcon />,
          subCategories: [
            {link: "cctv_cameras", text: "CCTV Cameras"}, 
            {link: "security_accessories", text: "Accessories"}
          ]
        }, 
        {
          text: 'Electrical Equipment',
          link: 'electrical_equipment',
          icon: <WbIncandescentOutlinedIcon />,
          subCategories: [
            {link: "light", text: "Light"}, 
            {link: "cables", text: "Cables"},
            {link: "electrical_accessories", text: "Accessories"}
          ]
        },
        {
          text: 'Solar Energy',
          link: 'solar_energy',
          icon: <EmojiObjectsOutlinedIcon />,
          subCategories: [
            {link: "solar_panel", text: "Solar Panel"}, 
            {link: "solar_light", text: "Solar Light"},
            {link: "inverters", text: "Inverters"},
            {link: "solar_accessories", text: "Accessories"},
          ]
        },
        {
          text: 'Furniture',
          link: 'furniture',
          icon: <WeekendOutlinedIcon />,
          subCategories: [
            {link: "tables", text: "Tables"}, 
            {link: "chairs", text: "Chairs"},
            {link: "sofas", text: "Sofas"}
          ]
        },
        {
          text: 'Industrial Equipment',
          link: 'main_industrial_equipment',
          icon: <RvHookupOutlinedIcon />,
          subCategories: [
            {link: "industrial_equipment", text: "All Industrial Equipment"}
          ]
        },
        {
          text: 'Commercial Equipment',
          link: 'main_commercial_equipment',
          icon: <LocalLaundryServiceOutlinedIcon />,
          subCategories: [
            {link: "commercial_equipment", text: "All Commercial Equipment"}
          ]
        },
        {
          text: 'Kitchen Appliances',
          link: 'main_kitchen_appliances',
          icon: <KitchenRoundedIcon />,
          subCategories: [
            {link: "kitchen_appliances", text: "All Kitchen Appliances"}
          ]
        }
      ].map((category) => (
        <div
        key={category.link}
        >
          <ListItem 
          button
          className="routerLink"
          onClick={(e) => handleClicked(e, category.link)}
          >
            { category.icon }
             <Typography variant='subtitle1' className={classes.listText} >
             {category.text}
             </Typography>
          </ListItem>

        <div style={{display: clicked === category.link ? "block" : "none"}}>
        <div 
        className={classes.subCategory}
        >
        {
        category.subCategories.map((subCategory) => (
          <div
          onClick={toggleDrawer(anchor, false)}
          key={subCategory.link}
          >
        <ListItem 
        button
        component={NavLink} 
        to={`/${subCategory.link}`}
        className="routerLink"
        activeStyle={active}
        onClick={triggerPageLoader}
        >
          <ArrowRightRoundedIcon fontSize='small' />
           <Typography variant='body2' className={classes.subListText} >
           {subCategory.text}
           </Typography>
        </ListItem>
        </div>
        ))}
        </div>
        <Divider />
        </div>

          </div>
        ))}
      </List>
      <Divider />
      <List>
        {[
        {
          text: 'Contact us',
          link: 'mailto: support@ntek.ng',
          icon: <MailIcon />
        },
        {
          text: 'Facebook',
          link: 'https://www.facebook.com/100068328626248',
          icon: <FacebookIcon />
        },
        {
          text: 'Instagram',
          link: 'https://www.instagram.com/ntek.ng',
          icon: <InstagramIcon />
        },
        {
          text: '08101463724',
          link: 'https://wa.me/+2348101463724',
          icon: <WhatsAppIcon />
        },
        {
          text: '08101463724',
          link: 'tel: +2348101463724',
          icon: <CallIcon />
        }
      ].map((category) => (
        <ListItem 
        button key={category.link}
        className="routerLink"
        onClick={toggleDrawer(anchor, false)}
        >
          { category.icon }
           <Typography variant='subtitle1' className={classes.listText} >
           <a 
                href={`${category.link}`}
                className="routerLink"
                >
                  {category.text}
                  </a>
           </Typography>
        </ListItem>
        ))}       
      </List>
    </div>
  ); 
  return (
    <div>
      {['left'].map((anchor) => (
        <React.Fragment key={anchor}>
          {
            iconType.iconType ? <MoreHorizRoundedIcon
              color="inherit"
              aria-label="open drawer"
              onClick={toggleDrawer(anchor, true)}
             /> : 
            <IconButton
              edge="start"
              className={classes.menuButton}
              color="inherit"
              aria-label="open drawer"
              onClick={toggleDrawer(anchor, true)}
            >
              <MenuIcon  fontWeight='bold' className={classes.iconWidth} />
            </IconButton>
            
          }
          <SwipeableDrawer
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
            onOpen={toggleDrawer(anchor, true)}
          >
            {list(anchor)}
          </SwipeableDrawer>
        </React.Fragment>
      ))}
    </div>
  );
}
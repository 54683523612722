import React, {Suspense, lazy} from 'react';
import  GeneralSkeleton  from "../utils/GeneralSkeleton";
import { Route, Switch } from 'react-router-dom'
const Televisions = lazy(() => import('../pages/Televisions'));
const MusicEquipment = lazy(() => import('../pages/MusicEquipment'));
const Moulders = lazy(() => import('../pages/Moulders'));
const FoodWarmer = lazy(() => import('../pages/FoodWarmer'));
const KitchenAppliances = lazy(() => import('../pages/KitchenAppliances'));
const Bakers = lazy(() => import('../pages/Bakers'));
const Mixer = lazy(() => import('../pages/Mixer'));
const TvAccessories = lazy(() => import('../pages/TvAccessories'));
const CommercialCookers = lazy(() => import('../pages/MusicEquipment'));
const Ovens = lazy(() => import('../pages/Ovens'));
const Blenders = lazy(() => import('../pages/Blenders'));
const CommercialEquipment = lazy(() => import('../pages/CommercialEquipment'));
const Fryers = lazy(() => import('../pages/Fryers'));
const IceMaker = lazy(() => import('../pages/IceMaker'));
const HomeAppliances = lazy(() => import('../pages/HomeAppliances'));
const Dehydrator = lazy(() => import('../pages/Dehydrator'));
const Juicer = lazy(() => import('../pages/Juicer'));
const WorkingTables = lazy(() => import('../pages/WorkingTables'));
const Slicer = lazy(() => import('../pages/Slicer'));
const Extractor = lazy(() => import('../pages/Extractor'));
const CctvCameras = lazy(() => import('../pages/CctvCameras'));
const SecurityAccessories = lazy(() => import('../pages/SecurityAccessories'));
const Light = lazy(() => import('../pages/Light'));
const Cables = lazy(() => import('../pages/Cables'));
const ElectricalAccessories = lazy(() => import('../pages/ElectricalAccessories'));
const SolarPanel = lazy(() => import('../pages/SolarPanel'));
const SolarLight = lazy(() => import('../pages/SolarLight'));
const Inverters = lazy(() => import('../pages/Inverters'));
const SolarAccessories = lazy(() => import('../pages/SolarAccessories'));
const Tables = lazy(() => import('../pages/Tables'));
const Chairs = lazy(() => import('../pages/Chairs'));
const Sofas = lazy(() => import('../pages/Sofas'));
const IndustrialEquipment = lazy(() => import('../pages/IndustrialEquipment'));

function Categories() {
    return (
      <Suspense fallback={<GeneralSkeleton />}>
        <Switch>
          <Route exact path="/televisions" component={Televisions} />
          <Route exact path="/music_equipment" component={MusicEquipment} />
          <Route exact path="/food_warmer" component={FoodWarmer} />
          <Route exact path="/kitchen_appliances" component={KitchenAppliances} />
          <Route exact path="/bakers" component={Bakers} />
          <Route exact path="/mixer" component={Mixer} />
          <Route exact path="/tv_accessories" component={TvAccessories} />
          <Route exact path="/commercial_cookers" component={CommercialCookers} />
          <Route exact path="/ovens" component={Ovens} />
          <Route exact path="/blenders" component={Blenders} />
          <Route exact path="/commercial_equipment" component={CommercialEquipment} />
          <Route exact path="/fryers" component={Fryers} />
          <Route exact path="/ice_maker" component={IceMaker} />
          <Route exact path="/home_appliances" component={HomeAppliances} />
          <Route exact path="/moulders" component={Moulders} />
          <Route exact path="/dehydrator" component={Dehydrator} />
          <Route exact path="/juicer" component={Juicer} />
          <Route exact path="/working_tables" component={WorkingTables} />
          <Route exact path="/slicer" component={Slicer} />
          <Route exact path="/extractor" component={Extractor} />
          <Route exact path="/cctv_cameras" component={CctvCameras} />
          <Route exact path="/security_accessories" component={SecurityAccessories} />
          <Route exact path="/light" component={Light} />
          <Route exact path="/cables" component={Cables} />
          <Route exact path="/electrical_accessories" component={ElectricalAccessories} />
          <Route exact path="/solar_panel" component={SolarPanel} />
          <Route exact path="/solar_light" component={SolarLight} />
          <Route exact path="/inverters" component={Inverters} />
          <Route exact path="/solar_accessories" component={SolarAccessories} />
          <Route exact path="/tables" component={Tables} />
          <Route exact path="/chairs" component={Chairs} />
          <Route exact path="/sofas" component={Sofas} />
          <Route exact path="/industrial_equipment" component={IndustrialEquipment} />
        </Switch>
      </Suspense>
    )
}

export default Categories
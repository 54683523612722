import React from "react";
import { Typography } from "@material-ui/core";

function HomeSkeleton() {
  return (
    <div className={`container pageComponents`}>
      <Typography style={{height: 800}}>

      </Typography>
    </div>
  );
}

export default HomeSkeleton;

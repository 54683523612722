import React from "react";
import Skeleton from "@material-ui/lab/Skeleton";
import Grid from "@material-ui/core/Grid";
import useStyles from "../styles/Skeleton";
import { Card } from "@material-ui/core";

export function TopLinksSkeleton() {
  const classes = useStyles();

  //quick Links
  const quickLinks = (
    <Grid item xs={4} md={2}>
        <Card className={classes.card}>
        <Grid container spacing={1} alignItems="center">
    <Grid item xs={12} md={3}>
      <Skeleton 
      animation="wave" 
        variant="circle" 
        width={40} height={40} 
        className={classes.auto}
        />
      </Grid>
      <Grid item xs={12} md={9}>
      <Skeleton
      className={classes.auto}
        animation="wave"
        variant="text"
        width={"90%"}
        height={10}
      />
      </Grid>
    </Grid>
    </Card>
    </Grid>
    
  );
  const frames = [];
  for (let index = 0; index < 6; index++) {
    frames.push(quickLinks);
  }


  return (
    <div className={`container pageComponents ${classes.verticalSpace}`}>

        <Grid
        className={classes.verticalSpace}
        container
        spacing={2}
      >
        {frames.map((frame, index) => (
          <React.Fragment key={index}>{frame}</React.Fragment>
        ))}
      </Grid>

    </div>
  );
}

export function ProductSectionSkeleton() {
  const classes = useStyles();
  const component = (
    <Grid item xs={6} sm={4} md={2}>
      <Skeleton animation="wave" variant="rect" width={"100%"} height={118} />
      <Skeleton animation="wave" variant="text" height={12} width={"100%"} />
      <Skeleton animation="wave" variant="text" height={12} width={"60%"} />
    </Grid>
  );
  const skeletons = [];

  for (let index = 0; index < 6; index++) {
    skeletons.push(component);
  }

  return (
    <div className={`container pageComponents ${classes.verticalSpace}`}>
      <Grid
        className={classes.mediumVerticalSpace}
        container
      >
       <Grid item xs={12}>
      <Skeleton 
      animation="wave" 
      variant="rect" 
      width={"100%"} 
      height={40} 
      className={classes.sectionRadius}
      />
    </Grid>
      </Grid>
      
    <Grid
        className={classes.verticalSpace}
        container
        spacing={2}
      >
        {skeletons.map((skeleton, index) => (
          <React.Fragment key={index}>{skeleton}</React.Fragment>
        ))}
      </Grid>
      </div>
  )

}


import React, { useState, useContext } from "react";
import { Grid, Paper, Typography, Divider } from "@material-ui/core";
import LiveTvRoundedIcon from "@material-ui/icons/LiveTvRounded";
import KitchenRoundedIcon from "@material-ui/icons/KitchenRounded";
import SpeakerGroupOutlinedIcon from '@material-ui/icons/SpeakerGroupOutlined';
import WbIncandescentOutlinedIcon from '@material-ui/icons/WbIncandescentOutlined';
import CameraOutlinedIcon from '@material-ui/icons/CameraOutlined';
import OutdoorGrillOutlinedIcon from '@material-ui/icons/OutdoorGrillOutlined';
import WeekendOutlinedIcon from '@material-ui/icons/WeekendOutlined';
import EmojiObjectsOutlinedIcon from '@material-ui/icons/EmojiObjectsOutlined';
import LocalLaundryServiceOutlinedIcon from '@material-ui/icons/LocalLaundryServiceOutlined';
import RvHookupOutlinedIcon from '@material-ui/icons/RvHookupOutlined';
import { Link } from "react-router-dom";
import PageLoaderHandler from "../utils/PageLoaderHandler";
import useStyles from "../styles/DisplayArea";
import Fab from "@material-ui/core/Fab";
import Zoom from "@material-ui/core/Zoom";
import UpIcon from "@material-ui/icons/KeyboardArrowUp";
import DownIcon from "@material-ui/icons/KeyboardArrowDown";
import { UseContext } from "../utils/UseContext";

function DesktopCategory(props) {
  const {menuClickCategory} = props
  const triggerPageLoader = PageLoaderHandler();
  const classes = useStyles();
  const [show, setShow] = useState(0);
  const [hoverColor, setHoverColor] = useState(null);
  const consumeContext = useContext(UseContext)

  const closeHoverCategory = async () => {
    await menuClickCategory && consumeContext.setHovState("mouseOut");
    await triggerPageLoader;
  }

  const handleCategoryMouseOver = (event, item) => {
    consumeContext.setSubcategories("mouseOver");
    setHoverColor(item)
  }
  const handleCategoryMouseOut = () => {
    consumeContext.setSubcategories("mouseOut")
  }
  const handleScrollTop = async () => {
    let firstCategory = document.getElementById("firstCategory");
    firstCategory.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "nearest",
    });
    setShow(0)
  };
  const handleScrollDown = () => {
    let lastCategory = document.getElementById("lastCategory");
    lastCategory.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "nearest",
    });
    setShow(1);
  };


  const items = [
      [
        {link: "/televisions", text: "Televisions"}, 
        {link: "/music_equipment", text: "Audio/Music Equipment"}
    ],
    [
      {link: "/commercial_equipment", text: "Commercial Equipment"}, 
      {link: "/kitchen_appliances", text: "Kitchen Appliances"} 
  ],
  [
    {link: "/televisions", text: "Televisions"}, 
    {link: "/music_equipment", text: "Audio/Music Equipment"},
    {link: "/tv_accessories", text: "Tv Accessories"},
    {link: "/home_appliances", text: "Home Appliances"},

],
[
  {link: "/ovens", text: "Ovens"}, 
  {link: "/fryers", text: "Fryers"},
  {link: "/moulders", text: "Moulders"},
  {link: "/bakers", text: "Bakers"},
  {link: "/food_warmer", text: "Food Warmer"},
  {link: "/ice_maker", text: "Ice Maker"},
  {link: "/mixer", text: "Mixer"},
],
[
  {link: "/cctv_cameras", text: "CCTV Cameras"}, 
  {link: "/security_accessories", text: "Accessories"}
],
[
  {link: "/light", text: "Light"}, 
  {link: "/cables", text: "Cables"},
  {link: "/electrical_accessories", text: "Accessories"}
],
[
  {link: "/solar_panel", text: "Solar Panel"}, 
  {link: "/solar_light", text: "Solar Light"},
  {link: "/inverters", text: "Inverters"},
  {link: "/solar_accessories", text: "Accessories"},
],
[
  {link: "/tables", text: "Tables"}, 
  {link: "/chairs", text: "Chairs"},
  {link: "/sofas", text: "Sofas"}
],
[
  {link: "/industrial_equipment", text: "All Industrial Equipment"}
],
[
  {link: "/commercial_equipment", text: "All Commercial Equipment"}
],
[
  {link: "/kitchen_appliances", text: "All Kitchen Appliances"} 
]
  ]

  const secondItemsDivision = [
    [],
    [],
    [],
    [
    {link: "/dehydrator", text: "Dehydrator"},
    {link: "/juicer", text: "Juicer"},
    {link: "/working_tables", text: "Working Tables"},
    {link: "/slicer", text: "Slicer"},
    {link: "/blenders", text: "Blenders"},
    {link: "/extractor", text: "Extractor"}
    ],
    [],
    [],
    [],
    [],
    [],
    [],
    []
  ]


  return (
    <div className={classes.paperContainer}>
      <Paper 
      elevation={1} 
      className={`${classes.category}`}
      style={{
        paddingTop: `${consumeContext.hover ? '38.6%' : '185%'}`, 
        boxShadow: `${menuClickCategory ? '3px 3px 6px 5px lightgrey' : ''}`,
      }}
      >


      <Paper 
      elevation={0} 
      className={classes.mainCategory}
      >


      <Grid
      container
      justify="flex-start"
      spacing={0}
      >
         {/* <div 
          wrap="nowrap"
          className={`${classes.intermediateContainer} customScrollBar`}
          onMouseOver={(event) => handleCategoryMouseOver(event, hoverColor)}
          onMouseOut={handleCategoryMouseOut}
          > */}
      <Grid
      item
      xs
      >


          <div 
          style={{ display: `${show === 0 && !menuClickCategory ? "block" : "none"}` }}
          >
            <Zoom in={true} unmountOnExit>
              <Fab
                onClick={handleScrollDown}
                aria-label="scroll to bottom"
                size="small"
                className={classes.categoryScrollFab}
                onMouseOver={(event) => handleCategoryMouseOver(event, hoverColor)}
                onMouseOut={handleCategoryMouseOut}
              >
                <DownIcon />
              </Fab>
            </Zoom>
          </div>
         
          <div 
          id="firstCategory" 
          className={`${classes.eachCategory}`}
          >
            <Grid
              container
              alignItems="center"
              wrap="nowrap"
              spacing={2}
              className={hoverColor === 0 ? "hoverColor" : "routerLink"}
              onMouseOver={(event) => handleCategoryMouseOver(event, 0)}
              onMouseOut={handleCategoryMouseOut}
            >
              <Grid item>
                <LiveTvRoundedIcon />
              </Grid>
              <Grid item xs zeroMinWidth>
                <Typography variant="body2" noWrap>
                  Eltak Official Store
                </Typography>
              </Grid>
            </Grid>
          </div>
          <div className={classes.eachCategory}>
            <Grid
              container
              alignItems="center"
              wrap="nowrap"
              spacing={2}
              className={hoverColor === 1 ? "hoverColor" : "routerLink"}
              onMouseOver={(event) => handleCategoryMouseOver(event, 1)}
              onMouseOut={handleCategoryMouseOut}
            >
              <Grid item>
                <KitchenRoundedIcon />
              </Grid>
              <Grid item xs zeroMinWidth>
                <Typography variant="body2" noWrap>
                  Lentz Official Store
                </Typography>
              </Grid>
            </Grid>
          </div>
          <div className={classes.eachCategory}>
            <Grid
              container
              alignItems="center"
              wrap="nowrap"
              spacing={2}
              className={hoverColor === 2 ? "hoverColor" : "routerLink"}
              onMouseOver={(event) => handleCategoryMouseOver(event, 2)}
              onMouseOut={handleCategoryMouseOut}
            >
              <Grid item>
                <SpeakerGroupOutlinedIcon />
              </Grid>
              <Grid item xs zeroMinWidth>
                <Typography variant="body2" noWrap>
                  Electronics
                </Typography>
              </Grid>
            </Grid>
          </div>
          <div className={classes.eachCategory}>
            <Grid
              container
              alignItems="center"
              wrap="nowrap"
              spacing={2}
              className={hoverColor === 3 ? "hoverColor" : "routerLink"}
              onMouseOver={(event) => handleCategoryMouseOver(event, 3)}
              onMouseOut={handleCategoryMouseOut}
            >
              <Grid item>
                <OutdoorGrillOutlinedIcon />
              </Grid>
              <Grid item xs zeroMinWidth>
                <Typography variant="body2" noWrap>
                Industrial Kitchen Equipment
                </Typography>
              </Grid>
            </Grid>
          </div>
          <div className={classes.eachCategory}>
            <Grid
              container
              alignItems="center"
              wrap="nowrap"
              spacing={2}
              className={hoverColor === 4 ? "hoverColor" : "routerLink"}
              onMouseOver={(event) => handleCategoryMouseOver(event, 4)}
              onMouseOut={handleCategoryMouseOut}
            >
              <Grid item>
                <CameraOutlinedIcon />
              </Grid>
              <Grid item xs zeroMinWidth>
                <Typography variant="body2" noWrap>
                Security & Surveillance
                </Typography>
              </Grid>
            </Grid>
          </div>
          <div className={classes.eachCategory}>
            <Grid
              container
              alignItems="center"
              wrap="nowrap"
              spacing={2}
              className={hoverColor === 5 ? "hoverColor" : "routerLink"}
              onMouseOver={(event) => handleCategoryMouseOver(event, 5)}
              onMouseOut={handleCategoryMouseOut}
            >
              <Grid item>
                <WbIncandescentOutlinedIcon />
              </Grid>
              <Grid item xs zeroMinWidth>
                <Typography variant="body2" noWrap>
                Electrical Equipment
                </Typography>
              </Grid>
            </Grid>
          </div>
          <div className={classes.eachCategory}>
            <Grid
              container
              alignItems="center"
              wrap="nowrap"
              spacing={2}
              className={hoverColor === 6 ? "hoverColor" : "routerLink"}
              onMouseOver={(event) => handleCategoryMouseOver(event, 6)}
              onMouseOut={handleCategoryMouseOut}
            >
              <Grid item> 
                <EmojiObjectsOutlinedIcon />
              </Grid>
              <Grid item xs zeroMinWidth>
                <Typography variant="body2" noWrap>
                  Solar Energy
                </Typography>
              </Grid>
            </Grid>
          </div>
          <div className={classes.eachCategory}>
            <Grid
              container
              alignItems="center"
              wrap="nowrap"
              spacing={2}
              className={hoverColor === 7 ? "hoverColor" : "routerLink"}
              onMouseOver={(event) => handleCategoryMouseOver(event, 7)}
              onMouseOut={handleCategoryMouseOut}
            >
              <Grid item>
                <WeekendOutlinedIcon />
              </Grid>
              <Grid item xs zeroMinWidth>
                <Typography variant="body2" noWrap>
                  Furniture
                </Typography>
              </Grid>
            </Grid>
          </div>
          <div className={classes.eachCategory}>
            <Grid
              container
              alignItems="center"
              wrap="nowrap"
              spacing={2}
              className={hoverColor === 8 ? "hoverColor" : "routerLink"}
              onMouseOver={(event) => handleCategoryMouseOver(event, 8)}
              onMouseOut={handleCategoryMouseOut}
            >
              <Grid item>
                <RvHookupOutlinedIcon />
              </Grid>
              <Grid item xs zeroMinWidth>
                <Typography variant="body2" noWrap>
                  Industrial Equipment
                </Typography>
              </Grid>
            </Grid>
          </div>
          <div className={classes.eachCategory}>
            <Grid
              container
              alignItems="center"
              wrap="nowrap"
              spacing={2}
              className={hoverColor === 9 ? "hoverColor" : "routerLink"}
              onMouseOver={(event) => handleCategoryMouseOver(event, 9)}
              onMouseOut={handleCategoryMouseOut}
            >
              <Grid item>
              <LocalLaundryServiceOutlinedIcon />
              </Grid>
              <Grid item xs zeroMinWidth>
                <Typography variant="body2" noWrap>
                  Commercial Equipment
                </Typography>
              </Grid>
            </Grid>
          </div>
          <div 
          id="lastCategory" 
          style={{position: 'relative'}}
          >
          <div className={classes.eachCategory}>
            <Grid
              container
              alignItems="center"
              wrap="nowrap"
              spacing={2}
              className={hoverColor === 10 ? "hoverColor" : "routerLink"}
              onMouseOver={(event) => handleCategoryMouseOver(event, 10)}
              onMouseOut={handleCategoryMouseOut}
            >
              <Grid item>
                <KitchenRoundedIcon />
              </Grid>
              <Grid item xs zeroMinWidth>
                <Typography variant="body2" noWrap>
                  Kitchen Appliances
                </Typography>
              </Grid>
            </Grid>
          </div>
         </div>
          
         
          

          <div 
          style={{ display: `${show === 1 && !menuClickCategory ? "block" : "none"}` }}
          >
            <Zoom in={true} unmountOnExit>
              <Fab
                onClick={handleScrollTop}
                aria-label="scroll to Top"
                size="small"
                className={classes.categoryScrollFab}
                onMouseOver={(event) => handleCategoryMouseOver(event, hoverColor)}
                onMouseOut={handleCategoryMouseOut}
              >
                <UpIcon />
              </Fab>
            </Zoom>
          </div>
          
        
        </Grid>
        {/* </div> */}

          <Grid
          style={{display: `${!consumeContext.hover ? 'none' : 'block'}`}}
        item
        xs={9}
        onMouseOver={(event) => handleCategoryMouseOver(event, hoverColor)}
        onMouseOut={handleCategoryMouseOut}
        >
          <div className={classes.subCatgoeryContainer}>
            <Grid
            container
            justify="flex-start"
            spacing={0}
            >

            <Grid item xs={4}>
          <Typography variant="h6" className={classes.subCatHeading}>
          Sub Categories
          </Typography>
          <Divider className={classes.divider} />

          {hoverColor != null && items[hoverColor].map((item, i) => (
            <Typography 
            key={i}
            component={Link}
            to={item.link}
            variant="subtitle1" 
            className={`${classes.subCatText} routerLink`}
            onClick={closeHoverCategory}
            >
              {item.text}
              </Typography>
          ))}
            </Grid>

            {(hoverColor != null && secondItemsDivision[hoverColor].length > 0) &&
              (<Grid item xs={4}>
          <Typography variant="h6" className={classes.emptyHeading}>
          </Typography>
          <Divider className={classes.divider} />
          {hoverColor != null && secondItemsDivision[hoverColor].map((item, i) => (
            <Typography 
            key={i}
            component={Link}
            to={item.link}
            variant="subtitle1" 
            className={`${classes.subCatText} routerLink`}
            onClick={closeHoverCategory}
            >
              {item.text}
              </Typography>
          ))}
            </Grid>)
            }

            </Grid>
          </div>
        </Grid>
          </Grid>

          </Paper>

        </Paper>

      
    </div>
  );
}

export default DesktopCategory;

import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  displayArea: {
    [theme.breakpoints.down("xs")]: {
      backgroundColor: "#fff",
      marginBottom: "10px",
    },
  },
  showCarouselContainer: {
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "64.45%",
    },
    display: 'block'
  },
  hideCarouselContainer: {
    display: 'none'
  },
  
  paperContainer: {
    width: "100%",
    height: "100%",
    padding: "6px 0px 7px 0px",
  },
  category: {
    position: "relative",
    padding: "4px 0px 4px 5px",
    overflowY: "hidden",
    "&:hover": {
      "& $categoryScrollFab": {
        display: 'flex'
      }
    }
  },
  mainCategory: {
    cursor: "pointer",
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%"
  },
  eachCategory: {
    color: "#424242",
    padding: "6px 8px 0.5px 8px",
    "&:hover": {
      color: "#e67700",
    },
    constantHoverColor: {
      color: "#e67700"
    },
  },
  categoryScrollFab: {
    display: 'none',
    position: "absolute",
    bottom: theme.spacing(1),
    right: theme.spacing(1),
  },
  subCatgoeryContainer: {
    borderLeft: '1px solid #e67700',
    height: '100%',
    padding: "10px 20px 0px 25px"
  },
  subCatHeading: {
    fontWeight: 'bold'
  },
  emptyHeading: {
    marginTop: 37
  },
  divider: {
    maxWidth: '250px',
    margin: '5px 0px'
  },
  subCatText: {
    display: 'block',
    color: '#404040',
    "&:hover": {
      color: "#000000",
    },
  },
  brandItem: {
    overflow: "hidden",
  },
  lazyLoad: {
    position: "relative",
    paddingTop: "96%",
  },
  brandImage: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
  },
  hideFrmMobile: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  hideFrmDesktop: {
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
}));
export default useStyles;

import React, { useContext, Suspense, lazy } from "react"
import { Grid, Paper } from "@material-ui/core"
import  { TopLinksSkeleton }  from "../utils/MainSkeleton";
import useStyles from "../styles/DisplayArea"
import { Link } from "react-router-dom"
import PageLoaderHandler from "../utils/PageLoaderHandler"
import CleanUpLoader from "../utils/CleanUpLoader"
import { Helmet } from "react-helmet-async"
import LazyLoad from 'react-lazyload'
import { forceCheck } from 'react-lazyload';
import { UseContext } from "../utils/UseContext";
import Carousel from "../components/Carousel"
import DesktopCategory from "../components/DesktopCategory"
const TopLinks = lazy(() => import('../components/TopLinks'));
const TopDeals = lazy(() => import('../components/TopDeals'));
const Electronics = lazy(() => import('../components/Electronics'));
const IndustrialEquipment = lazy(() => import('../components/IndustrialEquipment'));
const SecuritySurveillance = lazy(() => import('../components/SecuritySurveillance'));
const OfficialBrands = lazy(() => import('../components/OfficialBrands'));
const BrandImages = lazy(() => import('../components/BrandImages'));
const ElectricalEquipment = lazy(() => import('../components/ElectricalEquipment'));
const SolarEnergy = lazy(() => import('../components/SolarEnergy'));
const Furniture = lazy(() => import('../components/Furniture'));
const CommercialEquipment = lazy(() => import('../components/CommercialEquipment'));
const KitchenAppliances = lazy(() => import('../components/KitchenAppliances'));
const AboutUs = lazy(() => import('../components/AboutUs'));


function Home() {
  forceCheck()
  const triggerPageLoader = PageLoaderHandler();
  const classes = useStyles();
  CleanUpLoader();
  const consumeContext = useContext(UseContext)
  return (
    <React.Fragment>
      <Helmet>
        <title>Ntek Electronics | Nigerian electronics company</title>
        <meta
          name="description"
          content="Best Nigerian electronics store. Offers best shopping outlet for all kinds of electronics, including televisions, sound systems, commercial epuipments and lots more"
        />
      </Helmet>
      <div className={classes.displayArea}>
        <div className="pageComponents">
          <Grid
            container
            spacing={2}
            justify="space-between"
            alignItems="center"
          >
            <Grid
              item
              style={{ width: `${consumeContext.hover ? "82.55%" : "18.3%"}` }}
              className={classes.hideFrmMobile}
            >
              <DesktopCategory />
            </Grid>
            <Grid 
            item 
            className={
              !consumeContext.hover ? 
              classes.showCarouselContainer : 
              classes.hideCarouselContainer
            }
              >
              <div 
              elevation={0} 
              style={{overflow: 'hidden', maxHeight: 380}}
              >
               <Carousel /> 
              </div>
            </Grid>
            <Grid
              item
              container
              direction="column"
              justify="center"
              alignItems="center"
              spacing={2}
              className={classes.hideFrmMobile}
              style={{ width: "18.45%" }}
            >
              <Grid item style={{width: '100%'}}>
                <Paper elevation={1} className={classes.brandItem}>
                  <Link
                    to="lentz"
                    className="routerLink"
                    onClick={triggerPageLoader}
                  >
                     <div className="list">
            <LazyLoad
            className={classes.lazyLoad}
            once
            offset={100}
            >
                    <img
                      className={classes.brandImage}
                      src="images/brand/brand1.jpg"
                      alt="Lentz Brand"
                    />
                    </LazyLoad>
                    </div>
                  </Link>
                </Paper>
              </Grid>
              <Grid item style={{width: '100%'}}>
                <Paper elevation={1} className={classes.brandItem}>
                  <Link
                    to="eltak"
                    className="routerLink"
                    onClick={triggerPageLoader}
                  >
                    <div className={`${classes.lazyLoadContainer} list`} >
            <LazyLoad
            className={classes.lazyLoad}
            once
            offset={100}
            >
                    <img
                      className={classes.brandImage}
                      src="images/brand/brand2.jpg"
                      alt="eltak Brand"
                    />
                    </LazyLoad>
                    </div>
                  </Link>
                </Paper>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </div>

      <Suspense fallback={<TopLinksSkeleton />}>
      <TopLinks />
      <TopDeals />
    </Suspense>

    {/* <Suspense fallback={<div  />}>
      <TopDeals />
    </Suspense> */}

    <Suspense fallback={<div style={{height: 300}} />}>
      <BrandImages />
    </Suspense>

    <Suspense fallback={<div />}>
      <OfficialBrands />
    </Suspense>

    <Suspense fallback={<div />}>
      <Electronics />
    </Suspense>

    <Suspense fallback={<div />}>
      <IndustrialEquipment />
    </Suspense>

    <Suspense fallback={<div />}>
      <SecuritySurveillance />
    </Suspense>

    <Suspense fallback={<div />}>
      <ElectricalEquipment />
    </Suspense>

    <Suspense fallback={<div />}>
      <SolarEnergy />
    </Suspense>

    <Suspense fallback={<div />}>
      <Furniture />
    </Suspense>

    <Suspense fallback={<div />}>
      <CommercialEquipment />
    </Suspense>

    <Suspense fallback={<div />}>
      <KitchenAppliances />
    </Suspense>

    <Suspense fallback={<div />}>
      <AboutUs />
    </Suspense>
    
      {/* {localStorage.getItem("recentlyViewedItems") && <RecentlyViewed />} */}
    </React.Fragment>
  );
}

export default Home;
